import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";

import ApiService from "../helpers/Services.js";
import DocumentNotModal from "./DocumentNotInvoiceModal.js";

import Modal from "./modal/Modal.js";

const MODALS = {
  viewHierarchy: {
    component: Modal,
    props: { title: "View Hierarchy/Records" },
  },
  documentNotInvoice: {
    component: DocumentNotModal,
  },
  assignTo: {
    component: Modal,
    props: { title: "Assign to someone" },
  },
  needReferenceCopy: {
    component: Modal,
    props: { title: "Need Reference Copy" },
  },
};

const IsValidPanel = ({
  token = "",
  getTask = () => {
    return;
  },
  payload,
  disabled = false,
}) => {
  const reasons = [
    "1: File cannot be processed",
    "1: File is Password Protected",
    "2: File is not an Invoice",
    "3: File isn't Legible",
    "4: Invoice Data Cannot be Interpreted",
    "5: Amount is not in GBP",
    "5: Multiple Invoices",
    "6: Unsupported File Format",
    "8: Multiple Invoices",
  ];
  const [isNotInvoice, setIsNotInvoice] = useState(false);
  const [invalidDocReason, setInvalidDocReason] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);

  const [selectedModalKey, setSelectedModalKey] = useState(null);

  const handleModalClick = (modalKey) => {
    setDropdownOpen(false);
    setSelectedModalKey(modalKey);
    setIsModalOpen(true);
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    const { isValidDoc, invalidDocReason } = payload;
    if (isValidDoc == "N") {
      setIsNotInvoice(true);
    }
    if (invalidDocReason) {
      setInvalidDocReason(invalidDocReason);
    }
  }, [payload]);

  const handleCheckboxChange = () => {
    setIsNotInvoice(!isNotInvoice);
  };

  const handleNotInvoiceSubmit = async () => {
    // Logic for handling the submit action when checkbox is checked
    console.log("Document marked as not an invoice");
    // Add your submit logic here
    // setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let apiPayload = {
        id: payload?.docId,
        payload: {},
        isValidDoc: isNotInvoice ? "N" : "Y",
        invalidDocReason,
        useForTraining: payload?.useForTraining ? "Y" : "N",
      };
      const data = await ApiService.post("update-tasks", apiPayload, headers);
      if (data.status === "Success") {
        swal("Review Recorded", "Successfully", "success").then(() => {
          getTask();
        });
      }
    } catch (error) {
      //   setLoading(false);
      // toast.error(error.errorMsg);
      console.error(error);
      swal("Something went wrong!!", error?.errorMsg, "error");
    }
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setInvalidDocReason(newValue);
  };

  const ModalComponent = MODALS[selectedModalKey]?.component;
  const modalProps = MODALS[selectedModalKey]?.props;

  return (
    <>
      <div className="relative flex justify-end">
        <button
          ref={trigger}
          disabled={disabled}
          readOnly={disabled}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className={`bg-dark-gray border-none flex items-center justify-center gap-10 w-105 h-36 ${
            disabled && "cursor-not-allowed"
          }`}
        >
          <img src="/options.svg" alt="options-svg" className="size-18" />
          <p className="font-16-24-400">Options</p>
        </button>
        {/* <!-- Dropdown Start --> */}
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className={` absolute z-higher box-shadow-2 rounded-b-md right-0 top-10  w-267 bg-dark-gray ${
            dropdownOpen === true ? "block" : "hidden"
          }`}
        >
          <ul className="  w-full list-none flex flex-col mt-2  p-0">
            <li
              className="flex cursor-pointer items-center px-8 border-b border-dark-2 h-[50] capitalize  font-16-24-400 text-black-2 "
              onClick={() => handleModalClick("viewHierarchy")}
            >
              View Hierarchy/Records
            </li>
            <li
              onClick={() => handleModalClick("documentNotInvoice")}
              className="flex cursor-pointer items-center px-8  h-[50] border-b border-dark-2 capitalize  font-16-24-400 text-black-2 "
            >
              Document is not an Invoice
            </li>
            <li
              onClick={() => handleModalClick("assignTo")}
              className="flex cursor-pointer items-center px-8  h-[50] border-b border-dark-2 capitalize  font-16-24-400 text-black-2 "
            >
              Assign to someone
            </li>
            <li
              onClick={() => handleModalClick("needReferenceCopy")}
              className="flex cursor-pointer items-center px-8  h-[50] border-b border-dark-2 capitalize  font-16-24-400 text-black-2 "
            >
              Need Reference Copy
            </li>
          </ul>
        </div>
        {/* <!-- Dropdown End --> */}

        {isModalOpen && (
          <ModalComponent
            title={"Document is not an Invoice"}
            {...modalProps}
            onClose={setIsModalOpen}
            isNotInvoice={isNotInvoice}
            payload={payload}
            token={token}
            getTask={getTask}
          />
        )}
      </div>

      {/* TODO: use for reference in future */}
      <div
        style={{
          display: "none",
        }}
        className=" previous-review-data"
      >
        <p>
          {payload?.title} {JSON.stringify(payload)}
        </p>
        <div className="is-valid-panel">
          <div className="not-invoice-panel">
            <input
              type="checkbox"
              id={`isValid${disabled}`}
              name={`isValid${disabled}`}
              checked={isNotInvoice}
              onChange={handleCheckboxChange}
              disabled={disabled}
              readOnly={disabled}
            />
            <label htmlFor={`isValid${disabled}`}>
              Document is not an invoice
            </label>
          </div>
          {!disabled && isNotInvoice && (
            <>
              <hr />
              <h4>Select Reason</h4>
              <div className="select-container">
                <select value={invalidDocReason} onChange={handleSelectChange}>
                  {reasons.map((reason, index) => {
                    return (
                      <option key={index} value={reason}>
                        {reason}
                      </option>
                    );
                  })}
                </select>
                <div className="arrow-down">&#9660;</div>
              </div>
              <button onClick={handleNotInvoiceSubmit}>Submit</button>
            </>
          )}
          {disabled && isNotInvoice && (
            <>
              {" "}
              <hr />
              <h4>Reason</h4>
              <p>{payload?.invalidDocReason}</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default IsValidPanel;
