import React from "react";
import { useNavigate } from "react-router-dom";

function Button({ path, onClick, ...props }) {
  const navigate = useNavigate();

  const handlerOnClick = () => {
    if (path) {
      navigate(path);
      return;
    }
    onClick && onClick();
  };

  return (
    <button
      className={`text-white font-16-700 primary-btn border-none`}
      onClick={handlerOnClick}
      {...props}
    />
  );
}

export default Button;
