const PdfModal = ({ onClose, children }) => {
  const handlerClose = () => {
    onClose(false);
  };
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            style={{
              maxWidth: "60%",
              height: "70vh",
              overflow: "scroll",
            }}
            className="relative transform bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full "
          >
            <div className="flex justify-end h-61 bg-white px-4 z-top top-0 sticky items-center">
              <img
                src="/cross-dark.svg"
                className="h-24 cursor-pointer"
                alt="close-icon"
                onClick={handlerClose}
              />
            </div>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfModal;
