import React from "react";
import SearchInput from "./SearchInput.js";

const Header = ({
  title,
  searchTerm,
  handleSearch,
  onAddUser,
  onFilterUser,
  isAddUserButttonShown = true,
}) => {
  return (
    <div className="flex items-center justify-between ">
      <h1 className="font-27-36-400">{title}</h1>
      <div className="flex items-center gap-30">
        <SearchInput searchTerm={searchTerm} handleSearch={handleSearch} />
        {isAddUserButttonShown && (
          <button
            className="button h-38 text-black-2 border-dark border-1 font-16-700"
            onClick={() => onAddUser(true)}
          >
            Add User
          </button>
        )}
        <img
          onClick={() => onFilterUser(true)}
          src={"/filter.svg"}
          alt="filter-svg"
          className="cursor-pointer h-38"
        />
      </div>
    </div>
  );
};

export default Header;
