import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../context/AuthContext.js";
import { ClockLoader } from "react-spinners";

function AdminAuth({ children }) {
  let location = useLocation();
  const { user, loading } = UserAuth();

  if (loading) {
    // If the user data is still loading, return null or a loading indicator
    return (
      <div className="fixed h-screen w-screen flex items-center justify-center">
        <ClockLoader />
      </div>
    );
  }
  if (!user?.isAdmin) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default AdminAuth;
