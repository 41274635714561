import { useEffect, useRef, useState } from "react";

import DataTable from "../../components/dataTable/DataTable.js";
import Add from "../../components/add/Add.js";
import storage from "../../helpers/storage.js";
import ApiService from "../../helpers/Services.js";
import AssignRole from "../../components/assignRole/AssignRole.js";
import TableSkeleton from "../../components/skeleton/TableSkeleton.js";
import FilterModal from "../../components/filter-modal/FilterModal.js";
import Header from "../../components/Header.js";

const columns = [
  {
    field: "userid",
    type: "string",
    headerName: "Email",
    headerClassName: "bg-tb-header",
    // flex: 1,
    renderHeader: (params) => (
      <p className="text-table-header email-container m-0 p-0 font-18-400-24">
        {"Email "}
      </p>
    ),
    width: 200,
  },
  {
    field: "rolename",
    type: "string",
    headerName: "Current Role",
    headerAlign: "center",
    // width: 200,
    headerClassName: "bg-tb-header",
    flex: 1,
    renderHeader: (params) => (
      <p className="text-table-header email-container m-0 p-0 font-18-400-24">
        Current role
      </p>
    ),
    // rendercell if included then serach filter is not working on this field
    renderCell: (params) => {
      const role = params.row.rolename ?? "NA";
      return <p style={{ margin: "auto" }}>{role}</p>;
    },
  },

  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    // width: 150,
    headerClassName: "bg-tb-header",
    flex: 1,
    renderHeader: (params) => (
      <p className="text-table-header  m-0 p-0 font-18-400-24">Status</p>
    ),
    renderCell: (params) => {
      const isActive = params.row.status === "Active";
      return (
        <div className={" mx-auto"}>
          <p className="p-0 m-0">{isActive ? "Active" : "Disabled"}</p>
        </div>
      );
    },
  },
];

const Users = ({ token }) => {
  const [open, setOpen] = useState(false);
  const [isAssignRole, setIsAssignRole] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [userList, setUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const selectedUserRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // Add handleSearch function
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    if (event.target.value === "") {
      setFilteredUsers(userList);
      return;
    }
    const searchableProperties = ["userid", "rolename", "status"];
    const filtered = userList.filter((user) =>
      searchableProperties.some((property) =>
        String(user[property])
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    );

    setFilteredUsers(filtered);
  };

  const assignColumn = {
    field: "assign",
    headerName: "Role Action",
    // width: 180,
    headerAlign: "center",
    headerClassName: "bg-tb-header",
    renderHeader: (params) => (
      <p className="text-table-header email-container m-0 p-0 font-18-400-24">
        {"Role Action "}
      </p>
    ),
    renderCell: (params) => {
      const hasRole = params.row.rolename !== null;
      const isDisabled = params.row.status === "Disabled";

      if (isDisabled) {
        return <div className="user-not-active">--</div>;
      }
      return (
        <div className="w-full">
          {/* <Link to={`/${props.slug}/${params.row.id}`}> */}
          {hasRole ? (
            <button
              className="bg-change-role w-full font-14-19-400"
              onClick={() => {
                setIsAssignRole(true);
                selectedUserRef.current = {
                  email: params.row.userid,
                  title: "Change Role",
                  isAssignRole: false,
                  roleName: params.row.rolename,
                };
              }}
            >
              Change Role
            </button>
          ) : (
            <button
              className="bg-assign-role w-full font-14-19-400"
              onClick={() => {
                setIsAssignRole(true);
                selectedUserRef.current = {
                  email: params.row.userid,
                  title: "Assign Role",
                  roleName: params.row.rolename,
                  isAssignRole: true,
                };
              }}
            >
              Assign Role
            </button>
          )}
        </div>
      );
    },
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      let token = storage.getToken();
      console.log({ token });
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = await ApiService.post(
        "user-management",
        {
          action: "listUsers",
          payload: {
            vendor: "MT",
          },
        },
        headers
      );

      const refactorId = data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setUserList(refactorId);
      setFilteredUsers(refactorId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open || isAssignRole) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open, isAssignRole]);

  useEffect(() => {
    fetchUsers();
    storage.clearFileName();
  }, []);

  return (
    <div className="users flex flex-col gap-30 my-10">
      <Header
        title={"Users"}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        onAddUser={setOpen}
        onFilterUser={setIsFilterModalOpen}
      />
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <DataTable
          slug="users"
          columns={[...columns, assignColumn]}
          // rows={userList}
          rows={filteredUsers}
          fetchUsers={fetchUsers}
        />
      )}

      {isFilterModalOpen && (
        <FilterModal
          data={userList}
          setData={setFilteredUsers}
          onClose={setIsFilterModalOpen}
        />
      )}
      {open && (
        <Add
          token={token}
          slug="user"
          fetchUsers={fetchUsers}
          title="add new user"
          onClose={setOpen}
        />
      )}
      {isAssignRole && (
        <AssignRole
          title="Assign/Update Role"
          userRef={selectedUserRef?.current}
          onClose={setIsAssignRole}
          fetchUsers={fetchUsers}
        />
      )}
    </div>
  );
};

export default Users;
