import ProgressBar from "./progressBar/ProgressBar.js";
import Button from "./ui/Button.js";

const DocMetadata = ({ doc, switchProfile }) => {
  const isPreviewAvailable = doc?.prevPayload && doc.prevPayload?.data;
  return (
    <div className="relative ml-28 flex items-center  gap-20">
      <div className="doc-metadata-content">
        <ProgressBar
          size={100}
          trackWidth={7}
          indicatorWidth={7}
          total={Number(doc?.stats?.agent?.total)}
          // remaining={405}
          remaining={
            Number(doc?.stats?.agent?.total) -
            Number(doc?.stats?.agent?.remaining)
          }
        />
      </div>
      <div>
        <div className="doc-metadata-content text-white">
          <span className="font-16-24-400"> Batch</span>{" "}
          <h4 className="font-19-28-400">{doc?.payload?.clientBatchId}</h4>
        </div>
        <div className="doc-metadata-content text-white">
          <span className="font-16-24-400"> File </span>{" "}
          <h4 className="font-19-28-400">
            ({doc?.payload?.folder}) {doc?.clientItemId}
          </h4>
        </div>
      </div>

      {/* switch profile btn */}
      {isPreviewAvailable && (
        <Button
          onClick={() => switchProfile((prev) => !prev)}
          className="absolute right-100 -bottom-16px br-4 bg-purple-2 w-208  h-[50] text-white font-21-31-400 capitalize border-none"
        >
          <img src="./refresh.svg" className="size-23 mr-2" alt="refresh-svg" />
          Flip View
        </Button>
      )}
    </div>
  );
};

export default DocMetadata;
