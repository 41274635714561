import React, { useState, useCallback, useRef, useEffect } from "react";
import swal from "sweetalert";
import "./layout.css"; // Import a CSS file for custom styling
import JsonSchemaForm from "./JsonSchemaForm.js";
import ApiService from "../helpers/Services.js";
import { toast } from "react-toastify";
import storage from "../helpers/storage.js";

const FormBlock = ({ doc, setHighlight, getTask, token, isPreview, style }) => {
  const [width, setWidth] = useState(50);
  const [useForTrain, setUseForTrain] = useState(false);
  // const [loading, setLoading] = useState(false);
  const styles = {
    leftPanel: {
      width: `${width}%`,
      visibility: `${width < 35 ? "collapse" : "visible"}`,
    },
    rightPanel: {
      width: `${95 - width}%`,
      visibility: `${95 - width < 35 ? "collapse" : "visible"}`,
    },
    fullHeight: {
      height: "100%",
    },
  };
  const dataSet = [
    !isPreview
      ? {
          isVisible: doc.payload && doc.payload.data,
          // styling: doc.prevPayload && doc.prevPayload.data && styles.rightPanel,
          class: doc.prevPayload && doc.prevPayload.data ? "" : "col-12",
          data: doc,
          disable: false,
          showGeos: true,
          formData: doc.payload.data,
          geos: doc.payload.geos,
        }
      : {
          isVisible: doc.prevPayload && doc.prevPayload.data,
          // styling: styles.leftPanel,
          class: "",
          data: doc,
          disable: true,
          showGeos: true,
          formData: doc.prevPayload.data,
          geos: doc.prevPayload.geos,
        },
  ];

  const dragHandler = useCallback(
    (e) => {
      const w =
        ((e.clientX -
          e.currentTarget.parentElement.parentElement.getBoundingClientRect()
            .left) /
          e.currentTarget.parentElement.parentElement.getBoundingClientRect()
            .width) *
        100;
      if (w > 0 && w !== width) {
        setWidth(w);
      }
    },
    [width]
  );

  const handleSubmit = async (payload) => {
    // setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let apiPayload = {
        id: doc?.id,
        payload: payload?.formData,
        qa_payload: payload?.comment,
      };
      const data = await ApiService.post("update-tasks", apiPayload, headers);
      if (
        data.status === "Success" ||
        data.status === "Request queued successfully"
      ) {
        const localStorageSearchParam = storage.getFileName();
        getTask(localStorageSearchParam);
        //swal("Review Recorded", "Successfully", "success").then(() => {});
      }
    } catch (error) {
      //   setLoading(false);
      toast.error(error.errorMsg);
      console.error(error);
    }
  };

  const handleInputGeo = (data) => {
    const { geoLocation, value } = data;
    if (geoLocation && value) {
      const { Height, Width, Left, Top } = geoLocation?.g?.BoundingBox;
      let area = [
        {
          height: Height * 100,
          left: Left * 100,
          pageIndex: geoLocation?.p,
          top: Top * 100,
          width: Width * 100,
        },
      ];
      setHighlight({ area, value });
    } else {
      setHighlight({});
    }
  };

  const handleUseForTrain = (event) => {
    const { checked } = event.target;
    setUseForTrain(checked);
  };

  const getValidPayload = (doc, status) => {
    const { isValidDoc, invalidDocReason, payloadDesc } = status
      ? doc.payload
      : doc.prevPayload;
    let payload = {
      isValidDoc,
      invalidDocReason,
      title: payloadDesc,
    };
    if (status) {
      payload.docId = doc.id;
      payload.useForTraining = useForTrain;
    }
    return payload;
  };

  return (
    <div style={style}>
      <div className="flex items-center justify-center gap-20 w-242 h-67 bg-purple-2">
        <img src="/avatar.svg" alt="avatar-svg" className="size-10" />
        <p className="text-white font-21-31-400">
          {isPreview ? doc.prevPayload.payloadDesc: doc.payload.payloadDesc}
          {/* {doc?.payload?.payloadDesc?.substring(11) ?? "Amit Dixit"} */}
        </p>
      </div>

      <div className="flex-1 bg-white w-full border-block p-4 form-block">
        <div className="row" style={styles.fullHeight}>
          {dataSet.map((formData, i) => {
            return (
              formData.isVisible && (
                <React.Fragment key={i}>
                  <div style={formData.styling} className={formData.class}>
                    <JsonSchemaForm
                      isValidPayload={getValidPayload(
                        formData.data,
                        !formData.disable
                      )}
                      schema={formData.data.schema}
                      formData={formData.formData}
                      qa_payload={formData?.data?.prevPayload?.qa_payload}
                      disableForm={formData.disable}
                      useForTrain={formData.disable && handleUseForTrain}
                      getTask={!formData.disable && getTask}
                      token={!formData.disable && token}
                      postData={!formData.disable && ((e) => handleSubmit(e))}
                      geos={formData.geos}
                      highlightAlerts={!formData.disable}
                      getSelectedInput={formData.showGeos && handleInputGeo}
                      invalidDocReason={
                        formData?.data?.payload?.invalidDocReason
                      }
                    />
                  </div>
                  {/* {i == 0 && formData.disable && (
                    <div
                      draggable
                      onDrag={dragHandler}
                      className="draggable-container"
                    >
                      <div className="drag-bars" />
                    </div>
                  )} */}
                </React.Fragment>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FormBlock;
