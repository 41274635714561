import React, { useEffect, useState } from "react";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import useHighlightNotes from "./plugins/useHighlightNotes.js";
import jumpToPagePlugin from "./plugins/JumpToPagePlugin.js";

const DocumentViewer = ({
  doc,
  highlight,
  highlightImpLabels,
  isModal,
  style,
}) => {
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Rotate,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
              padding: "0 10px",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: "0px 1px", width: "5%" }}>
              <CurrentPageInput />
            </div>
            /
            <div style={{ padding: "0px 2px" }}>
              <NumberOfPages />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <EnterFullScreen />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Download />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Rotate />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) =>
      defaultTabs.filter((tab) => {
        return (
          tab.title !== "Bookmark" &&
          tab.title !== "Attachment" &&
          tab.title !== "Thumbnail"
        );
      }),
    renderToolbar,
    toolbarPlugin: {
      fullScreenPlugin: {
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageWidth);
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageWidth);
        },
      },
    },
  });

  const jumpToPagePluginInstance = jumpToPagePlugin();
  const { jumpToPage } = jumpToPagePluginInstance;
  const { highlightPluginInstance, setNotes, noteId } = useHighlightNotes();

  const [highlightCheckbox, setHighlightCheckbox] = useState(false);

  useEffect(() => {
    if (highlightCheckbox) {
      let labels = [];
      let notes = [];

      for (var label in highlightImpLabels) {
        const geoLocation = highlightImpLabels[label]["g"];
        const value = highlightImpLabels[label]["t"];
        const pageIndex = highlightImpLabels[label]["p"];
        if (geoLocation && value) {
          const { Height, Width, Left, Top } = geoLocation?.BoundingBox;
          let area = [
            {
              height: Height * 100,
              left: Left * 100,
              top: Top * 100,
              pageIndex: pageIndex,
              width: Width * 100,
            },
          ];
          labels.push({ area, value });
        }
      }
      let id = 0;

      for (var label in labels) {
        const { area, value } = labels[label];
        if (area?.length && value) {
          const note = {
            id: ++id,
            content: value,
            highlightAreas: area,
            pageIndex: area[0].pageIndex,
            highlightAll: true,
            quote: value,
          };
          notes.push(note);
        }
      }
      if (notes.length > 0) {
        setNotes(notes);
      } else {
        setNotes([]);
      }
    } else {
      setNotes([]);
    }
  }, [highlightImpLabels, highlightCheckbox]);

  const handleCheckboxChange = () => {
    setHighlightCheckbox((prevState) => !prevState);
  };

  const checkOnload = () => {
    setHighlightCheckbox(true);
  };
  useEffect(() => {
    checkOnload();
  }, []);

  useEffect(() => {
    const { area, value } = highlight;
    if (area?.length && value) {
      let id = noteId;
      const note = {
        id: ++id,
        content: value,
        highlightAreas: area,
        quote: value,
      };
      setNotes([note]);
      let pageNum = area[0].pageIndex;
      jumpToPage(pageNum);
    } else {
      setNotes([]);
    }
  }, [highlight]);

  return (
    <div
      style={style}
      className={isModal ? "pdf-viewer-modal" : "pdf-viewer bg-white"}
    >
      <div className="toolbar px-4">
        <input
          type="checkbox"
          checked={highlightCheckbox}
          onChange={handleCheckboxChange}
        />
        <label className="font-18-600-24 ml-2">Highlight key labels</label>
      </div>
      <Viewer
        fileUrl={doc?.signedURL}
        defaultScale={SpecialZoomLevel.PageWidth}
        plugins={[
          defaultLayoutPluginInstance,
          highlightPluginInstance,
          jumpToPagePluginInstance,
        ]}
      />
    </div>
  );
};

export default DocumentViewer;
