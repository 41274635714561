import "./ProgressBar.css";

const ProgressBar = (props) => {
  let {
    size = 150,
    trackWidth = 10,
    trackColor = `#FFFFFF`,
    indicatorWidth = 10,
    indicatorColor = `#0DD175`,
    indicatorCap = `round`,
    label = `Progress`,
    labelColor = `#FFFFFF`,
    spinnerMode = false,
    spinnerSpeed = 1,
    total = 100,
    remaining = 70,
  } = props;

  const progress = Math.round((remaining / total) * 100);

  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  let hideLabel = size < 100 || !label.length || spinnerMode ? true : false;

  return (
    <>
      <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
        <svg className="svg-pi" style={{ width: size, height: size }}>
          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className={`svg-pi-indicator ${
              spinnerMode ? "svg-pi-indicator--spinner" : ""
            }`}
            style={{ animationDuration: spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>

        {!hideLabel && (
          <div className="svg-pi-label " style={{ color: labelColor }}>
            <span className="svg-pi-label__loading font-16-24-400 text-white">
              {label}
            </span>

            {!spinnerMode && (
              <span className="svg-pi-label__progress">
                <span className="text-white flex font-27-40-400 poppins-regular">{`${props.remaining}/${props.total}`}</span>
                {/* {`${progress > 100 ? 100 : progress}%`} */}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProgressBar;
