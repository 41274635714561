import React from "react";
import CommentModal from "./modal/CommentModal.js";

function Bubble({ addComment, fieldName, comment, hasComment, ...props }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function toggleHandler() {
    setIsModalOpen((prev) => !prev);
  }

  return (
    <div>
      <img
        src={hasComment ? "./bubble-view.svg" : "./bubble-add.svg"}
        alt="bubble-add"
        className=" cursor-pointer"
        onClick={() => toggleHandler()}
      />

      {isModalOpen && (
        <CommentModal
          addComment={addComment}
          fieldName={fieldName}
          comment={comment}
          onClose={() => setIsModalOpen(false)}
          {...props}
        />
      )}
    </div>
  );
}

export default Bubble;
