import { useState } from "react";
import Modal from "./Modal.js";

const COMMENTS = ["T-Typing", "R-Rules", "T-Wrong keyword", "T-Miss PO"];
const LINECOMMENTS = ["T-Typing", "R-Rules", "T-Extra line", "T-Miss line"];

const OptionRadio = ({ options, value, onChange, name }) => {
  return (
    <div className="flex flex-col my-2">
      {options?.map((option, index) => (
        <div key={index} className="flex items-center justify-start gap-20">
          <input
            type="radio"
            id={`option${index}`}
            className="m-0"
            name={name}
            value={option}
            checked={value === option}
            onChange={onChange}
          />
          <label htmlFor={`option${index}`} className="font-18-400-24 ml-2 m-0">
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};

const CommentModal = ({
  addComment,
  comment,
  fieldName,
  onClose,
  isLineField,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    isLineField
      ? comment?.["lineItems"]?.[fieldName]
      : comment?.[fieldName] || ""
  );

  const lineItems = comment?.lineItems || [];

  const handleOptionChange = (event) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);

    if (isLineField) {
      const updatedLineItems = [...lineItems];
      updatedLineItems[fieldName] = newSelectedOption;
      addComment((prevData) => ({
        ...prevData,
        lineItems: updatedLineItems,
      }));
    } else {
      addComment((prevData) => ({
        ...prevData,
        [fieldName]: newSelectedOption,
      }));
    }
  };

  return (
    <Modal title={"Add Comment"} onClose={onClose}>
      <div className="add">
        <div className="modal-user">
          <div className="px-4 my-2 py-2">
            <h2 className="text-black font-23-34-400 py-2">
              Select below comment and submit to add comment
            </h2>

            <div className="flex flex-col my-2  ">
              <OptionRadio
                options={isLineField ? LINECOMMENTS : COMMENTS}
                value={selectedOption}
                onChange={handleOptionChange}
                name="comment"
              />
            </div>

            <div className="py-2 w-182 mt-5">
              <button
                type="button"
                onClick={() => onClose()}
                className=" border-none w-full h-49 font-18-600-24 capitalize justify-center rounded-md bg-[#3C394D] px-8  text-sm font-semibold text-white shadow-sm  sm:w-auto"
              >
                submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CommentModal;
