import React, { useState, useEffect, useContext, useMemo } from "react";
import PasteDataContext from "../context/PasteDataContext.js";
import IsValidPanel from "./IsValidPanel.js";
import Bubble from "./Bubble.js";

function ToastItem({ keyPrefix = "field", item, index, onRemove }) {
  if (item === "") {
    return null;
  }

  const id = `toast-${keyPrefix}-${index}`;

  return (
    <div
      key={id}
      id={id}
      className="h-44 px-8 bg-light-red text-red flex items-center justify-between font-16-21-400"
    >
      <span className=" flex items-center gap-10">
        <img
          src="/comment.svg"
          className="h-24 cursor-pointer"
          alt="close-icon"
        />
        {item}
      </span>

      <img
        src="/cross-dark.svg"
        className="h-24 cursor-pointer"
        alt="close-icon"
        onClick={() => {
          onRemove?.(index);
          document.getElementById(id).style.display = "none";
        }}
      />
    </div>
  );
}

const JsonSchemaForm = ({
  highlightAlerts = false,
  schema,
  formData: initialFormData,
  postData,
  geos = {},
  getSelectedInput,
  disableForm = false,
  showGeos = true,
  useForTrain = () => {
    return;
  },
  getTask = () => {
    return;
  },
  isValidPayload,
  token,
  qa_payload,
  invalidDocReason,
}) => {
  const { pasteData, setPasteData } = useContext(PasteDataContext);
  const [formData, setFormData] = useState(initialFormData || {});
  const [formErrors, setFormErrors] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const initialComment = useMemo(() => {
    if (disableForm) return {};

    if (Object.keys(qa_payload || {}).length > 0) {
      return qa_payload;
    }

    return Object.keys(schema.properties || {}).reduce((acc, key) => {
      if (schema.properties[key].type === "string") {
        acc[key] = "";
      } else if (schema.properties[key].type === "array") {
        const arrayLength = initialFormData?.lineItems?.length || 0;
        acc[key] = Array(arrayLength).fill("");
      }
      return acc;
    }, {});
  }, [disableForm, qa_payload, schema.properties, initialFormData]);

  const [comment, setComment] = useState(initialComment);

  const handleRemoveComment = (keyToRemove) => {
    setComment((prevComment) => {
      const updatedComment = { ...prevComment };
      // delete updatedComment[keyToRemove];

      updatedComment[keyToRemove] = "";
      return updatedComment;
    });
  };

  const handleRemoveArrayIndex = (index) => {
    setComment((prevComment) => {
      const updatedComment = { ...prevComment };

      updatedComment["lineItems"][index] = "";
      return updatedComment;
    });
  };

  useEffect(() => {
    setFormData(initialFormData || {});
  }, [initialFormData]);

  useEffect(() => {
    if (pasteData && !disableForm) {
      // Create a copy of the current line items
      const updatedLineItems = [...(formData.lineItems || [])];

      // Insert the new item from pasteData at the specified index
      // The first parameter of splice is the index, the second parameter (0) means no items are removed
      updatedLineItems.splice(pasteData?.index, 0, pasteData?.item);

      // Update the formData state with the new line items array
      setFormData((prevData) => ({ ...prevData, lineItems: updatedLineItems }));

      // Reset pasteData to null
      setPasteData(null);
    }
  }, [pasteData, disableForm, formData.lineItems]);

  const styles = {
    formContainer: {
      // padding: "20px",
      // margin: "20px",
      backgroundColor: "#FFFFFF",
      // backgroundColor: "green",

      borderRadius: "10px",
    },
    formLabel: {
      display: "block",
      marginBottom: "5px",
    },
    formRequired: {
      color: "red",
      fontWeight: "bolder",
      // fontSize: "1.5rem",
    },
    formGroup: {
      marginBottom: "4px",
      width: "50%",
    },
    tableSection: {
      minWidth: "300px",
    },
    input: {
      padding: "8px",
      borderRadius: "5px",
      width: "100%",
      border: "1px solid #5E718D",
      // marginBottom: "5px",
      outline: "none",
      color: "#2D3643",
      height: "2.875rem",
      fontSize: "1rem",
    },
    addButton: {
      backgroundColor: "#1c9d46",
      color: "white",
      padding: "8px 15px",
      borderRadius: "5px",
      border: "none",
    },
    removeButton: {
      backgroundColor: "none",
      color: "white",
      padding: "8px 15px",
      borderRadius: "5px",
      border: "none",
    },
    pasteButton: {
      backgroundColor: "#fff",
      color: "#8500ff",
      padding: "0 5px",
      borderRadius: "5px",
      border: "none",
      fontSize: "2.5rem",
      cursor: "pointer",
    },
    submitButton: {
      // backgroundColor: "#2a9de9",
      // color: "white",
      padding: "10px 20px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      fontSize: "1.25rem !important",
    },
    errorAlert: {
      marginBottom: "20px",
      padding: "1rem",
      border: "1px solid red",
      backgroundColor: "#ff000026",
      color: "red",
      borderRadius: "5px",
      maxHeight: "30rem",
      overflow: "auto",
    },
    disabledButton: {
      backgroundColor: "#ccc",
      color: "#666",
      padding: "8px 15px",
      borderRadius: "5px",
      border: "none",
      cursor: "not-allowed",
    },
    disabledInput: {
      backgroundColor: "#f4f4f4",
      outline: "none",
    },
    lineItemBlock: {
      // width: "50rem",
    },
  };

  const handleFocus = (geoLocation, value) => {
    getSelectedInput({ geoLocation, value });
  };

  const renderInput = (fieldName, fieldSchema) => {
    if (fieldSchema.type === "array") {
      return null;
      // return <hr style={{ margin: "10px 0", border: "1px solid #ccc" }} />;
    }

    let hasComment = comment?.[fieldName] || "";

    const commonInputProps = {
      type: "text",
      name: fieldName,
      value: formData[fieldName] || "",
      onClick: () => {
        showGeos && handleFocus(geos[fieldName], formData[fieldName]);
      },
      onChange: handleChange,
      placeholder: fieldSchema.uiLabel || "",
      style: {
        ...styles.input,
        ...(disableForm && styles.disabledInput),
      },
      className: `font-16-24-400  ${hasComment && "border-red"}`,
    };

    if (fieldSchema.format === "date") {
      return (
        <div className="flex gap-2 items-center">
          <input {...commonInputProps} type="date" readOnly={disableForm} />
          {!disableForm && (
            <Bubble
              addComment={setComment}
              fieldName={fieldName}
              comment={comment}
              hasComment={hasComment}
            />
          )}
        </div>
      );
    }

    if (disableForm) {
      return <input {...commonInputProps} readOnly />;
    }

    const pattern = fieldSchema.pattern;
    const inputValue = formData[fieldName] || "";

    const isValid = !pattern || new RegExp(pattern).test(inputValue);

    const inError = Object.keys(formErrors).filter((err) => err == fieldName);

    return (
      <>
        <div className="flex gap-2 items-center">
          <input {...commonInputProps} />
          <Bubble
            addComment={setComment}
            fieldName={fieldName}
            comment={comment}
            hasComment={hasComment}
          />
        </div>

        {!isValid && formData[fieldName] != "" && !inError.length && (
          <div style={{ color: "red", fontSize: "1rem" }}>
            {`Invalid ${fieldSchema.uiLabel || fieldName} pattern`}
          </div>
        )}
      </>
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // setFormErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const handleRemoveArrayItem = (index) => {
    const updatedLineItems = [...(formData.lineItems || [])];
    updatedLineItems.splice(index, 1);
    setFormData((prevData) => ({ ...prevData, lineItems: updatedLineItems }));
  };

  const handlePasteArrayItem = (index) => {
    const updatedLineItems = [...(formData.lineItems || [])];
    let obj = { item: updatedLineItems[index], index };
    setPasteData(obj);
  };

  const handleArrayItemChange = (event, index) => {
    const { name, value } = event.target;
    //alert(index)
    setSelectedRowIndex(index);

    setFormData((prevData) => {
      const updatedLineItems = (prevData.lineItems || []).map((item, i) =>
        i === index
          ? {
              ...item,
              [name.includes(`lineItems[${index}]`)
                ? name.replace(`lineItems[${index}].`, "")
                : name]: value,
            }
          : item
      );

      return { ...prevData, lineItems: updatedLineItems };
    });
  };

  const handleArrayItemSelection = (event, index) => {
    const { name, value } = event.target;
    //alert(index)
    setSelectedRowIndex(index);
  };

  const handleAddArrayItem = () => {
    const updatedLineItems = [...(formData.lineItems || [])];
    // If no row is selected, add to the end of the list
    const insertIndex =
      selectedRowIndex >= 0 ? selectedRowIndex + 1 : updatedLineItems.length;
    updatedLineItems.splice(insertIndex, 0, {});
    setFormData((prevData) => ({ ...prevData, lineItems: updatedLineItems }));
    // Reset selected row index
    setSelectedRowIndex(-1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!disableForm) {
      const errors = validateFormData(formData);
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        console.log("Form has errors:", errors);
      } else {
        setFormErrors({});
        if (formData) postData({ formData, comment });
        console.log("Form submitted successfully:", formData);
      }
    }
  };

  const validateFormData = (data) => {
    const errors = {};

    schema.required.forEach((fieldName) => {
      schema.properties[fieldName].required = true;
    });

    Object.keys(schema.properties).forEach((fieldName) => {
      const fieldSchema = schema.properties[fieldName];
      const fieldValue = data[fieldName];
      const pattern = fieldSchema?.pattern;
      const isValid = pattern && new RegExp(pattern).test(fieldValue);

      if (fieldValue && pattern && !isValid) {
        errors[fieldName] = `${fieldSchema.uiLabel || fieldName} is invalid`;
      }
      if (fieldSchema.required && (!fieldValue || fieldValue.trim() === "")) {
        errors[fieldName] = `${fieldSchema.uiLabel || fieldName} is required`;
      }
    });

    if (schema.properties.lineItems && data.lineItems) {
      let lineItemsErrors = {};
      schema.properties.lineItems.items.required.forEach((fieldName) => {
        schema.properties.lineItems.items.properties[fieldName].required = true;
      });
      Object.keys(schema.properties.lineItems.items.properties).forEach(
        (fieldName) => {
          const fieldLineItemSchema =
            schema.properties.lineItems.items.properties[fieldName];
          const lineItemPattern = fieldLineItemSchema?.pattern;

          data.lineItems.map((fieldLineItemValue, i) => {
            const lineItemIsValid =
              lineItemPattern &&
              new RegExp(lineItemPattern).test(fieldLineItemValue[fieldName]);

            if (
              fieldLineItemValue[fieldName] &&
              lineItemPattern &&
              !lineItemIsValid
            ) {
              lineItemsErrors[`lineItems[${i}][${fieldName}]`] = `${
                fieldLineItemSchema.uiLabel || fieldName
              } is invalid`;
            }
            if (
              fieldLineItemSchema.required &&
              (!fieldLineItemValue[fieldName] ||
                fieldLineItemValue[fieldName].trim() === "")
            ) {
              lineItemsErrors[`lineItems[${i}][${fieldName}]`] = `${
                fieldLineItemSchema.uiLabel || fieldName
              } is required`;
            }
          });
        }
      );
      let convertedLineIetemErrors = transformObject(lineItemsErrors);
      Object.assign(errors, convertedLineIetemErrors);
    }

    return errors;
  };

  function transformObject(obj) {
    const transformedObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const match = key.match(/lineItems\[(\d+)\]\[(\w+)\]/);

        if (match) {
          const index = match[1];
          const field = match[2];

          const newKey = `${field}-${index}`;
          const value = `In lineItems row ${parseInt(index) + 1}, ${obj[key]}`;
          transformedObj[newKey] = value;
        }
      }
    }

    return transformedObj;
  }

  const renderTableButtons = () => {
    return (
      !disableForm && (
        <button
          type="button"
          title="Add a Row (Row is added below selected row)"
          onClick={handleAddArrayItem}
          className={`text-white font-16-700 border-none bg-none`}

          // style={{ ...styles.addButton }}
        >
          {/* <span className="glyphicon glyphicon-plus"></span> */}
          <img src="/add.svg" className="w-62 h-24" />
        </button>
      )
    );
  };

  const renderTableSecondaryButton = (index) => {
    return !disableForm ? (
      <button
        type="button"
        title="Remove Row"
        onClick={() => handleRemoveArrayItem(index)}
        className={`text-white font-16-700 border-none bg-none`}

        // style={{ ...styles.removeButton }}
      >
        <img src="/minus.svg" className="size-30 ml-4" />
      </button>
    ) : (
      <button
        type="button"
        title="Add row to the right"
        onClick={() => handlePasteArrayItem(index)}
        style={{ ...styles.pasteButton }}
      >
        <span className="glyphicon glyphicon-paste "></span>
      </button>
    );
  };

  return (
    <>
      <IsValidPanel
        token={token}
        disabled={disableForm}
        payload={isValidPayload}
        getTask={getTask}
      />
      <div style={styles.formContainer}>
        {invalidDocReason && !disableForm && (
          <div className="my-4">
            <ToastItem
              keyPrefix="invalid-doc-reason"
              item={invalidDocReason}
              index={invalidDocReason}
            />
          </div>
        )}

        {/* TODO: implement in future if required */}
        {/* <div className="flex flex-col gap-10 my-3 ">
          {Object.entries(comment)
            .filter(([key, value]) => {
              if (Array.isArray(value) && value.length === 0) {
                return false;
              } else {
                return value !== "" && value !== null && value !== undefined;
              }
            })
            .map(([key, value]) => {
              if (Array.isArray(value)) {
                return value.map((item, index) => (
                  <ToastItem
                    key={index}
                    keyPrefix="lineItems"
                    item={item}
                    index={index}
                    onRemove={handleRemoveArrayIndex}
                  />
                ));
              }

              return (
                <ToastItem
                  key={key}
                  item={value}
                  index={key}
                  onRemove={handleRemoveComment}
                />
              );
            })}
        </div> */}

        <form onSubmit={handleSubmit} className=" ">
          <div className="flex flex-wrap">
            {Object.keys(schema.properties).map((fieldName) => {
              const fieldSchema = schema.properties[fieldName];
              const isAlertRed = highlightAlerts && fieldSchema.alert === "red";
              const labelStyle = {
                ...styles.formLabel,
                color: isAlertRed ? "red" : "#160E57",
              };

              return (
                <div
                  key={fieldName}
                  style={styles.formGroup}
                  className="flex flex-col  px-2 "
                >
                  <label
                    htmlFor={fieldName}
                    style={labelStyle}
                    className="font-16-24-400 mb-2"
                  >
                    {fieldSchema.uiLabel || fieldName}
                    {schema?.required.includes(fieldName) && (
                      <span style={styles.formRequired}>*</span>
                    )}
                  </label>

                  {renderInput(fieldName, fieldSchema)}
                  {formErrors[fieldName] && (
                    <div style={{ color: "red" }}>{formErrors[fieldName]}</div>
                  )}
                </div>
              );
            })}

            {schema.properties.lineItems && (
              <div
                className={` px-10 py-2 w-full ${
                  disableForm && "bg-background-2 py-4"
                } `}
              >
                <table style={{ width: "100%" }} className="">
                  <thead>
                    <tr>
                      {!disableForm && (
                        <th key={"chat-bubule"} className="font-16-24-400"></th>
                      )}
                      {Object.keys(
                        schema.properties.lineItems.items.properties
                      ).map((itemFieldName) => (
                        <th key={itemFieldName} className="font-16-24-400">
                          {schema.properties.lineItems.items.properties[
                            itemFieldName
                          ].uiLabel || itemFieldName}
                        </th>
                      ))}
                      <th>{renderTableButtons()}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.lineItems &&
                      formData.lineItems.map((item, index) => {
                        // handle in case we got undefined value then set ""
                        const hasComment =
                          (comment?.lineItems?.[index] || "") !== "" || false;
                        return (
                          <tr key={index}>
                            {!disableForm && (
                              <td className="pr-4 pb-2">
                                <Bubble
                                  addComment={setComment}
                                  fieldName={index}
                                  comment={comment}
                                  hasComment={hasComment}
                                  isLineField
                                />
                              </td>
                            )}

                            {Object.keys(
                              schema.properties.lineItems.items.properties
                            ).map((itemFieldName) => (
                              <td key={itemFieldName} className="pr-4 pb-2">
                                <input
                                  type="text"
                                  className="h-16"
                                  name={`lineItems[${index}].${itemFieldName}`}
                                  title={item[itemFieldName] || ""}
                                  value={item[itemFieldName] || ""}
                                  onChange={(event) =>
                                    handleArrayItemChange(event, index)
                                  }
                                  onFocus={(event) =>
                                    handleArrayItemSelection(event, index)
                                  }
                                  onClick={(event) =>
                                    showGeos &&
                                    handleFocus(
                                      geos["lineItems"][item["id"]],
                                      item["desc"]
                                    )
                                  }
                                  placeholder={
                                    schema.properties.lineItems.items
                                      .properties[itemFieldName].uiLabel || ""
                                  }
                                  style={{
                                    ...styles.input,
                                    ...(disableForm && styles.disabledInput),
                                    ...(hasComment && {
                                      border: "1px solid red",
                                    }),
                                  }}
                                  readOnly={disableForm}
                                />
                                {formErrors.lineItems &&
                                  formErrors.lineItems[index] &&
                                  formErrors.lineItems[index][
                                    itemFieldName
                                  ] && (
                                    <div style={{ color: "red" }}>
                                      {
                                        formErrors.lineItems[index][
                                          itemFieldName
                                        ]
                                      }
                                    </div>
                                  )}
                              </td>
                            ))}
                            <td>{renderTableSecondaryButton(index)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {formErrors.lineItems && (
                  <div style={{ color: "red", marginLeft: "15px" }}>
                    {formErrors.lineItems}
                  </div>
                )}
              </div>
            )}

            <div className="w-full my-5">
              {!disableForm && (
                <button
                  type="submit"
                  className="bg-dark-blue w-full text-white font-18-600-24"
                  style={{ ...styles.submitButton }}
                >
                  Submit
                </button>
              )}
              {disableForm && (
                <div className="use-for-train">
                  <label htmlFor="useForTraining">Use For Training</label>
                  <input
                    type="checkbox"
                    id="useForTraining"
                    name="useForTraining"
                    onChange={useForTrain}
                  />
                </div>
              )}
            </div>

            {Object.keys(formErrors).length > 0 && (
              <div style={{ ...styles.errorAlert }}>
                <strong>Error:</strong> There are validation errors in the form.
                {Object.keys(formErrors).map((keyName, i) => (
                  <li key={i}>
                    <span>{formErrors[keyName]}</span>
                  </li>
                ))}
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default JsonSchemaForm;
