import React, { useState } from "react";
import Modal from "../modal/Modal.js";

function FilterModal({ data, onClose, setData }) {
  const [column, setColumn] = useState("");
  const [operator, setOperator] = useState(filterOperators[0]);
  const [value, setValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredData = data.filter((item) => {
      const fieldValue = item[column] || "";
      if (operator.requiresFilterValue) {
        return operator.getApplyFilterFn(fieldValue, value.toLowerCase());
      } else {
        return operator.getApplyFilterFn(fieldValue);
      }
    });
    console.log({ filteredData });
    setData(filteredData);
    onClose(false);
  };

  return (
    <Modal title="Filter Records" onClose={onClose}>
      <form onSubmit={handleSubmit} className="px-4 my-2 py-2">
        <h2 className="text-black font-23-34-400 py-2">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. 
        </h2>

        <div className="flex flex-col my-4 ">
          <label className="mb-2 font-20-30-400" id="email">
            Columns
          </label>
          <select
            className="font-16-21-400 h-46 p-0 px-3"
            value={column}
            onChange={(e) => setColumn(e.target.value)}
          >
            <option value="">Select</option>
            <option value="userid">Email</option>
            <option value="rolename">Role</option>
            <option value="status">Status</option>

            {/* Add more options here */}
          </select>
        </div>
        <div className="flex flex-col my-4 ">
          <label className="mb-2 font-20-30-400" id="email">
            operator
          </label>

          <select
            className="font-16-21-400 h-46 p-0 px-3"
            value={operator.value}
            // onChange={(e) => setOperator(e.target.value)}
            onChange={(e) =>
              setOperator(
                filterOperators.find((op) => op.value === e.target.value)
              )
            }
          >
            {filterOperators.map((op, i) => (
              <option key={i} value={op.value}>
                {op.value}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col my-4 ">
          <label className="mb-2 font-20-30-400" id="email">
            value
          </label>
          <input
            type="text"
            placeholder="abc@gmail.com"
            className="border-input font-16-21-400 font-normal  w-3/4 px-3 h-46  br-6"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            aria-describedby="input-description"
          />
        </div>

        <div className="py-2 w-182">
          <button className=" border-none w-full h-49 font-18-600-24 capitalize justify-center rounded-md bg-[#3C394D] px-8  text-sm font-semibold text-white shadow-sm  sm:w-auto">
            submit
          </button>
        </div>
      </form>
    </Modal>
  );
}

const filterOperators = [
  {
    value: "contains",
    requiresFilterValue: true,
    getApplyFilterFn: (fieldValue, filterValue) => {
      return fieldValue.toLowerCase().includes(filterValue);
    },
  },
  {
    value: "equals",
    requiresFilterValue: true,
    getApplyFilterFn: (fieldValue, filterValue) => {
      return fieldValue.toLowerCase() === filterValue;
    },
  },
  {
    value: "startsWith",
    requiresFilterValue: true,
    getApplyFilterFn: (fieldValue, filterValue) => {
      return fieldValue.toLowerCase().startsWith(filterValue);
    },
  },
  {
    value: "endsWith",
    requiresFilterValue: true,
    getApplyFilterFn: (fieldValue, filterValue) => {
      return fieldValue.toLowerCase().endsWith(filterValue);
    },
  },
  {
    value: "isEmpty",
    requiresFilterValue: false,
    getApplyFilterFn: (fieldValue) => {
      return fieldValue.length === 0;
    },
  },
  {
    value: "isNotEmpty",
    requiresFilterValue: false,
    getApplyFilterFn: (fieldValue) => {
      return fieldValue.length > 0;
    },
  },
  {
    value: "isAnyOf",
    requiresFilterValue: true,
    getApplyFilterFn: (fieldValue, filterValue) => {
      const filterValues = filterValue.split(",").map((v) => v.toLowerCase());
      return filterValues.includes(fieldValue.toLowerCase());
    },
  },
];
export default FilterModal;
