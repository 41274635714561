import React, { useCallback } from "react";

function Divider({ width, setWidth }) {
  const dragHandler = useCallback(
    (e) => {
      const w =
        ((e.clientX -
          e.currentTarget.parentElement.parentElement.getBoundingClientRect()
            .left) /
          e.currentTarget.parentElement.parentElement.getBoundingClientRect()
            .width) *
        100;
      if (w > 0 && w !== width) {
        setWidth(w);
      }
    },
    [width]
  );

  return (
    <div
      draggable
      onDrag={dragHandler}
      className=" cursor-resize relative flex gap-3px items-center  justify-center w-15  h-screen bg-divider mt-17 rounded-md "
    >
      <img src="./bar.svg" alt="divider-svg" className="w-3 h-44" />
      <img src="./bar.svg" alt="divider-svg" className="w-3 h-44" />
    </div>
  );
}

export default Divider;
