import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext.js";
import Button from "./ui/Button.js";
import DropdownUser from "./ui/DropdownUser.js";

const Navbar = ({ getTask, doc }) => {
  const { user } = UserAuth();
  const [textBoxValue, setTextBoxValue] = useState("");

  const handleReviewInvoice = () => {
    getTask(textBoxValue);
    setTextBoxValue(""); // Reset the input field
  };

  // TODO: implement in future if required
  // useEffect(() => {
  //   if (doc?.clientItemId) {
  //     setTextBoxValue(doc.clientItemId);
  //   }
  // }, [doc]);

  return (
    <div className="flex px-10 items-center justify-between">
      <div className=" flex items-center gap-48">
        <img src="/sps-2.svg" className="w-43 h-41" alt="sps-logo" />
        {user?.isAdmin && (
          <Button
            className="border-none w-93 h-44 rounded-[40px] font-16-21-400 bg-linearGradient text-white"
            path="/dashboard/users"
          >
            Admin
          </Button>
        )}
      </div>

      <div className="flex items-center gap-48">
        <div className="border br-8 w-381 bg-white h-39 flex items-center justify-between">
          <input
            type="text"
            className="h-35 px-2 w-full font-12-14-400 flex border-none"
            placeholder="Optional Invoice File Name"
            value={textBoxValue}
            onChange={(e) => setTextBoxValue(e.target.value)}
          />

          <button
            className="h-35 br-6 border-none text-center font-14-16-400 min-w-133 bg-linearGradient-2 text-white"
            onClick={handleReviewInvoice}
          >
            Review Invoice
          </button>
        </div>

        <DropdownUser shouldDisplayEmail={false} isDarkTheme />
      </div>
    </div>
  );
};

export default Navbar;
