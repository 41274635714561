import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import DataTable from "../dataTable/DataTable.js";
import ApiService from "../../helpers/Services.js";
import TableSkeleton from "../skeleton/TableSkeleton.js";
import "./roleDetail.scss";
import Header from "../Header.js";
import FilterModal from "../filter-modal/FilterModal.js";

const columns = [
  {
    field: "userid",
    type: "string",
    headerName: "Email",
    width: 200,
    headerClassName: "bg-tb-header",
    renderHeader: (params) => (
      <p className="text-table-header email-container m-0 p-0 font-18-400-24">
        {"Email "}
      </p>
    ),
  },
  {
    field: "rolename",
    type: "string",
    headerName: "Role",
    flex: 1,
    headerClassName: "bg-tb-header",
    renderHeader: (params) => (
      <p className="text-table-header email-container m-0 p-0 font-18-400-24">
        {"Current role"}
      </p>
    ),
  },
];

function RoleDetail() {
  let { role } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const fetchUserInRole = async () => {
    try {
      setIsLoading(true);
      const data = await ApiService.post("user-management", {
        action: "listUsersInRoles",
        payload: { vendor: "MT", roleName: role },
      });

      const refactorId = data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setRoles(refactorId);
      setFilteredUsers(refactorId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    if (event.target.value === "") {
      setFilteredUsers(roles);
      return;
    }
    const searchableProperties = ["userid", "rolename", "status"];
    const filtered = roles?.filter((user) =>
      searchableProperties.some((property) =>
        String(user[property])
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    );

    setFilteredUsers(filtered);
  };

  useEffect(() => {
    fetchUserInRole();
  }, [role]);

  return (
    <section className="role-detail">
      {/* <div className="role-container">
        <Link to={"/dashboard/roles/admin"} className={`role-card ${role}`}>
          <p>{role}</p>
        </Link>
      </div> */}

      <Header
        title={role}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        // onAddUser={setOpen}
        isAddUserButttonShown={false}
        onFilterUser={setIsFilterModalOpen}
      />

      {isFilterModalOpen && (
        <FilterModal
          data={roles}
          setData={setFilteredUsers}
          onClose={setIsFilterModalOpen}
        />
      )}
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <div className="role-table">
          <DataTable
            slug="users"
            columns={columns}
            rows={filteredUsers}
            hideActionColumn
          />
        </div>
      )}
    </section>
  );
}

export default RoleDetail;
