import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext.js";

function DropdownUser({ shouldDisplayEmail = true, isDarkTheme = false }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);

  const { user, handlerLogout } = UserAuth();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="flex items-center gap-10">
      <img src="/avatar.svg" alt="avatar-svg" className="size-10" />
      {shouldDisplayEmail && (
        <span className="lineheight-28 font-16-600 text-grey">
          {user?.email}
        </span>
      )}

      <Link
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center gap-4"
        href="#"
      >
        <img
          src={isDarkTheme ? "caret-down-light.svg" : "/arrow-down.svg"}
          alt="arrow-down-svg"
        />
      </Link>

      {/* <!-- Dropdown Start --> */}
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={` absolute z-higher box-shadow-2 rounded-b-md right-0 top-32 w-200 bg-white ${
          dropdownOpen === true ? "block" : "hidden"
        }`}
      >
        <ul className="  w-full list-none flex flex-col mt-2  p-0">
          <li>
            <Link
              href="/change-password"
              className="flex items-center px-8 py-4 border-b border-grey capitalize  lineheight-21 font-16-600 text-black-2 "
            >
              change password
            </Link>
          </li>
          <li>
            <Link
              href="/profile"
              className="flex items-center px-8 py-4 capitalize border-b border-grey lineheight-21 font-16-600 text-black-2 "
            >
              profile
            </Link>
          </li>
          <li>
            <Link
              onClick={handlerLogout}
              href="#"
              className="flex items-center px-8 py-4 capitalize border-b border-grey lineheight-21 font-16-600 text-black-2 "
            >
              sign out
            </Link>
          </li>
        </ul>
      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  );
}

export default DropdownUser;
