import { useState } from "react";
import { toast } from "react-toastify";

import ApiService from "../../helpers/Services.js";
import Modal from "../modal/Modal.js";

const Add = ({ title, fetchUsers, slug, onClose }) => {
  const [email, setEmail] = useState("");

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      await ApiService.post("user-management", {
        action: "createUser",
        payload: {
          vendor: "MT",
          email: email,
        },
      });

      toast.success("User Created Successfully!", {
        position: "top-right",
      });
      fetchUsers?.();
    } catch (error) {
      toast.error(error?.message ? error.message : error + "");
      console.error(error?.message ? error.message : error + "");
    }

    onClose(false);
  };

  return (
    <Modal title={title} onClose={onClose}>
      <div className="add">
        <div className="modal-user">
          <form onSubmit={handleSubmit} className="px-4 my-2 py-2">
            <h2 className="text-black font-23-34-400 py-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. 
            </h2>

            <div className="flex flex-col my-4 ">
              <label className="mb-2 font-20-30-400" id="email">
                Email
              </label>

              <input
                type="email"
                placeholder="abc@gmail.com"
                className="border-input font-16-21-400 font-normal  w-3/4 px-3 h-46  br-6"
                value={email}
                onChange={handleInputChange}
                aria-describedby="input-description"
              />
            </div>

            <div className="py-2 w-182">
              <button className=" border-none w-full h-49 font-18-600-24 capitalize justify-center rounded-md bg-[#3C394D] px-8  text-sm font-semibold text-white shadow-sm  sm:w-auto">
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default Add;
