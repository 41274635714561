import React from "react";

const SearchInput = ({ handleSearch, searchTerm }) => {
  return (
    <label className="relative flex items-center mb-0">
      <span className="sr-only">Search</span>
      <input
        className="font-16-21-400 border-light-3 focus-within:outline-red-500 px-2 border-b w-319 h-38 placeholder:font-medium "
        placeholder="Type here to search..."
        value={searchTerm}
        onChange={(event) => handleSearch(event)}
      />
      <span className="absolute right-1 flex items-center pr-3">
        <img
          className="h-24"
          //   onClick={() => alert("search box")}
          src="/search.svg"
        />
      </span>
    </label>
  );
};

export default SearchInput;
