import { Link } from "react-router-dom";
import Button from "../ui/Button.js";
import DropdownUser from "../ui/DropdownUser.js";
import { UserAuth } from "../../context/AuthContext.js";

const Navbar = () => {
  const { user } = UserAuth();

  return (
    <div className="relative py-4 px-14 w-full bg-white box-shadow">
      <div className="w-full flex justify-between">
        <img src="/sps-logo.svg" alt="sps-logo" />

        <div className="gap-20 flex items-center">
          {!user?.email ? (
            <>
              <Link className="capitalize font-16-700 px-8" to={"/login"}>
                Sign in
              </Link>

              <Button path="/create-account">Create account</Button>
            </>
          ) : (
            <DropdownUser />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
