import React, { useState, useCallback, useRef, useEffect } from "react";
import "./layout.css"; // Import a CSS file for custom styling
import ApiService from "../helpers/Services.js";
import Navbar from "./Navbar.js";
import DocMetadata from "./DocMetadata.js";
import DocumentViewer from "./DocumentViewer.js";
import { toast } from "react-toastify";
import FormBlock from "./FormBlock.js";
import Loader from "./Loader.js";
import DocumentDisplay from "./DocumentDisplay.js";
import { useLocation, useSearchParams } from "react-router-dom";
import storage from "../helpers/storage.js";
import Button from "./ui/Button.js";
import PdfModal from "./PdfModal.js";
import Divider from "./ui/Divider.js";

const Layout = ({ token }) => {
  const location = useLocation();
  let [searchParams] = useSearchParams();

  const [highlight, setHighlight] = useState({});
  const [doc, setDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [shouldPrevShown, toggleProfile] = useState(false);
  const [width, setWidth] = useState(50);

  const styles = {
    leftPanel: {
      width: `${width}%`,
    },
    rightPanel: {
      width: `${95 - width}%`,
    },
  };

  const isPdf = (fileName) => {
    return fileName && fileName.toLowerCase().includes(".pdf");
  };

  const getTask = async (textValue) => {
    setLoading(true);
    setHighlight({});
    setDoc(null);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = await ApiService.post(
        "assign-task",
        { clientItemId: (textValue && textValue.trim()) || "" },
        headers
      );
      setLoading(false);
      setDoc(data);

      // Append the query parameter to the current URL
      storage.setFileName(data?.clientItemId);
      const newUrl = `${location.pathname}?filename=${data?.clientItemId}`;
      window.history.pushState({}, "", newUrl);
      //toast.success("Document Fetched Successfully!", {
      //  position: "top-right",
      //});
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      console.error(error);
    }
  };

  useEffect(() => {
    const filename = searchParams?.get("filename");
    const localStorageSearchParam = storage.getFileName();

    if (filename && filename !== localStorageSearchParam) {
      storage.setFileName(filename);
    }
    if (filename || localStorageSearchParam) {
      getTask(filename ?? localStorageSearchParam);
    }
  }, [location]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = doc.signedURL;
    link.download = doc.fileName || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isPreviewAvailable = doc?.prevPayload && doc.prevPayload?.data;
  // const isPreviewAvailable = false;

  useEffect(() => {
    isPreviewAvailable && toggleProfile(false);
  }, [doc]);

  return (
    <div className="layout-container bg-background ">
      <div className="bg-dark-blue pb-20 pt-10 flex flex-col space-y-5">
        <Navbar getTask={getTask} doc={doc} />
        {(doc && isPdf(doc.signedURL) && (
          <DocMetadata doc={doc} switchProfile={toggleProfile} />
        )) ||
          (doc && <DocMetadata doc={doc} switchProfile={toggleProfile} />)}
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="flex pb-20 bg-opacity-80 -mt-10 gap-30 px-10">
          {doc && (
            <>
              <FormBlock
                doc={doc}
                setHighlight={setHighlight}
                getTask={getTask}
                token={token}
                isPreview={false}
                style={styles.leftPanel}
              />
              <Divider width={width} setWidth={setWidth} />
            </>
          )}

          {isPreviewAvailable && shouldPrevShown ? (
            <FormBlock
              doc={doc}
              setHighlight={setHighlight}
              getTask={getTask}
              token={token}
              isPreview={true}
              style={styles.rightPanel}
            />
          ) : doc && isPdf(doc.signedURL) ? (
            <DocumentViewer
              doc={doc}
              highlight={highlight}
              highlightImpLabels={(doc.label_geos || []).concat(
                doc.payload.valueGeos
              )}
              isModal={false}
              style={styles.rightPanel}
            />
          ) : (
            doc && (
              <DocumentDisplay
                src={doc?.signedURL}
                setHighlight={setHighlight}
                highlight={highlight}
                highlightImpLabels={(doc.label_geos || []).concat(
                  doc.payload.valueGeos
                )}
              />
            )
          )}
        </div>
      )}
      {isPreviewAvailable && shouldPrevShown && (
        <div className="flex items-center justify-center pb-20">
          <Button
            onClick={() => setIsPdfModalOpen(true)}
            className="br-6 bg-view-pdf w-267  h-[50] text-white font-18-600-24 border-none"
          >
            View Pdf
          </Button>
        </div>
      )}

      {isPdfModalOpen && (
        <PdfModal title="add new user" onClose={setIsPdfModalOpen}>
          <DocumentViewer
            doc={doc}
            highlight={highlight}
            highlightImpLabels={(doc.label_geos || []).concat(
              doc.payload.valueGeos
            )}
            isModal={true}
          />
        </PdfModal>
      )}
    </div>
  );
};

export default Layout;
