const Config = {
  StackName: 'beta-doc-analyzer',
  DefaultImageName: 'default_document_3.png',
  CognitoIdentityPoolId: 'us-east-1:4874d9ca-4e29-4868-8922-2f3e0f0cd84c',
  CognitoUserPoolId: 'us-east-1_6wZAIGbLb',
  DeployRegion: 'us-east-1',
  SNSTopicArn: 'arn:aws:sns:us-east-1:266164767636:uat-doc-analyzer-textractcognitodemotopicEEA53D4C-JxDwjE8G86Kq',
  DefaultBucketName: 'uat-doc-analyzer-textractcognitodemobucket90cf6a3-se7zrbpq3vt3',
  DocumentStorageLambdaArn:
    "arn:aws:lambda:us-east-1:266164767636:function:uat-get-doc-tasks",
  DocumentAnalyzerLambdaArn:
    "arn:aws:lambda:us-east-1:266164767636:function:uat-DocAnalyzer-services",  
  CognitoId: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_10Jwb6V8c',
  APIUrl: 'https://u27opiugfa.execute-api.us-east-1.amazonaws.com/prod',
  LogoutUrl: 'https://prod-sps-doc-processor.auth.us-east-1.amazoncognito.com/logout?client_id=4o2epvebd7fsjgss37e3c8inka&logout_uri=https://beta.mineraltree.sce.us.spsglobal.com&response_type=token',
  LoginUrl: 'https://prod-sps-doc-processor.auth.us-east-1.amazoncognito.com/login?client_id=4o2epvebd7fsjgss37e3c8inka&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://beta.mineraltree.sce.us.spsglobal.com',
  RoleArn: 'arn:aws:iam::266164767636:role/uat-doc-analyzer-textractcognitodemotextractrole79-1BSDMI0ONFEUR',
  QueueUrl: 'https://sqs.us-east-1.amazonaws.com/266164767636/uat-doc-analyzer-textractcognitodemoqueue80660218-a25h9hC7QRFt',
};

export default Config;
