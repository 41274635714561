import { useState } from "react";
import swal from "sweetalert";

import ApiService from "../helpers/Services.js";
import Modal from "./modal/Modal.js";
import storage from "../helpers/storage.js";

const OPTIONS = [
  { value: "file_can_not_be_processed", label: "File can not be processed" },
  {
    value: "file_is_password_protected",
    label: "File is password protected",
  },
  { value: "file_is_not_an_invoice", label: "File is not an invoice" },
  { value: "file_is_not_legible", label: "File is not legible" },
  {
    value: "invoice_data_can_not_be_interpreted",
    label: "Invoice data can not be interpreted",
  },
  { value: "amount_is_not_in_gbp", label: "Amount is not in GBP" },
  { value: "multiple_invoices", label: "Multiple Invoices" },
  { value: "unsupported_file_format", label: "Unsupported File Format" },
];

const DocumentNotInvoiceModal = ({
  title,
  onClose,
  isNotInvoice,
  payload,
  token,
  getTask,
}) => {
  const invalidDocReason = payload?.invalidDocReason;
  const [selectedOption, setSelectedOption] = useState(
    invalidDocReason ?? null
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedOption) {
      swal("Please Select at leaset one option!!", "", "error");
      return;
    }
    const localStorageSearchParam = storage.getFileName();

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let apiPayload = {
        id: payload?.docId,
        payload: {},
        isValidDoc: selectedOption ? "N" : isNotInvoice,
        invalidDocReason: selectedOption,
        useForTraining: payload?.useForTraining ? "Y" : "N",
      };
      const data = await ApiService.post("update-tasks", apiPayload, headers);
      if (data.status === "Request queued successfully") {
        // swal("Review Recorded", "Successfully", "success").then(() => {
        getTask(localStorageSearchParam);
        // });
      }
    } catch (error) {
      console.error(error);
      swal("Something went wrong!!", error?.errorMsg, "error");
    }

    onClose(false);
  };
  return (
    <Modal title={title} onClose={onClose}>
      <div className="add">
        <div className="modal-user">
          <form onSubmit={handleSubmit} className="px-4 my-2 py-2">
            <p className="font-23-34-400 pb-2">
              Select below reason why document is not an invoice
            </p>
            {OPTIONS.map((option) => {
              const selected = selectedOption === option.value;
              return (
                <label
                  key={option.value}
                  htmlFor={`multi-select-${option.value}`}
                  className="flex gap-10 items-center font-23-34-400"
                >
                  <input
                    type="checkbox"
                    id={`multi-select-${option.value}`}
                    name="multi-select"
                    value={option.value}
                    checked={selected}
                    onChange={handleOptionChange}
                  />
                  {option.label}
                </label>
              );
            })}
            <div className="py-2 w-182 mt-4">
              <button className=" border-none w-full h-49 font-18-600-24 capitalize justify-center rounded-md bg-[#3C394D] px-8  text-sm font-semibold text-white shadow-sm  sm:w-auto">
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentNotInvoiceModal;
