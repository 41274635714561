import { useLocation } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext.js";
import MenuItem from "../ui/MenuItem.js";

const menu = [
  {
    key: 1,
    title: "Homepage",
    path: "/",
    icon: "/home.png",
  },
  {
    key: 2,
    title: "User",
    path: "/dashboard/users",
    icon: "/list.svg",
  },
  {
    key: 3,
    title: "Role",
    path: "/dashboard/roles",
    icon: "/user-2.svg",
    isBottomBorder: false,
    listItems: [
      {
        key: 4,
        title: "Admin role",
        path: "/dashboard/roles/Admin",
        icon: "/caret-right.svg",
        isSubMenu: true,
        isBottomBorder: false,
        padding: "p-3",
      },
      {
        key: 5,
        title: "Frontline role",
        path: "/dashboard/roles/Frontline",
        icon: "/caret-right.svg",
        isSubMenu: true,
        isBottomBorder: false,
        padding: "p-3",
      },
      {
        key: 6,
        title: "Supervisor role",
        path: "/dashboard/roles/Supervisor",
        icon: "/caret-right.svg",
        isSubMenu: true,
        isBottomBorder: false,
        padding: "p-3",
      },
    ],
  },
];

const SideBar = () => {
  const { handlerLogout, user } = UserAuth();
  const { pathname } = useLocation();
  return (
    <div className="h-full relative bg-primary px-3">
      <div className="flex items-center gap-10 py-5 px-4">
        <img src="/avatar-image.svg" />
        <span className="font-24-32-600 text-white-2 truncated-text">
          {user?.email || "jay foster"}
        </span>
      </div>

      {menu.map((item) => {
        const isParentPathActive = pathname === item.path;
        return (
          <div key={`menu-${item.key}`}>
            <MenuItem key={item.key} {...item} />
            {item.listItems?.map((listItem) => (
              <MenuItem
                key={listItem.key}
                {...listItem}
                activeBg={isParentPathActive}
              />
            ))}
          </div>
        );
      })}

      <div
        className=" absolute h-55 bottom-40 left-4 right-4 bg-light-primary flex items-center px-5 gap-20"
        onClick={handlerLogout}
      >
        <img src={"/sign-out.svg"} alt="" />
        <span className="text-white font-18-600-24">Sign out</span>
      </div>
    </div>
  );
};

export default SideBar;
