import React from "react";

function Modal({ title = "title", onClose, children }) {
  const handlerClose = () => {
    onClose(false);
  };

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              {/* header */}
              <div className="flex justify-between h-61 bg-[#0078D4] px-4  items-center">
                <h1
                  id="modal-title"
                  className="text-white font-23-34-400 font-normal capitalize"
                >
                  {title}
                </h1>
                <img
                  src="/close.svg"
                  className="h-24 cursor-pointer"
                  alt="close-icon"
                  onClick={handlerClose}
                />
              </div>

              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
