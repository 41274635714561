import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuItem = ({
  path,
  title,
  icon,
  isBottomBorder = true,
  isSubMenu = false,
  padding = "py-4 px-2",
  activeBg = false,
}) => {
  const { pathname } = useLocation();

  const isParentActive =
    pathname.includes("/dashboard/roles") && isSubMenu && pathname !== path;

  const backroundColor =
    pathname === path
      ? "bg-active"
      : isSubMenu && activeBg
      ? "bg-active-sub-menu"
      : "";

  return (
    <div
      className={`flex items-center ${padding} ${
        isBottomBorder && "border-b border-light-2 "
      }
      ${isParentActive ? "bg-active-sub-menu" : backroundColor}
      `}
    >
      <Link
        to={path}
        className={`flex w-full gap-30 items-center  px-2 py-0  `}
      >
        <img src={icon} />
        <span
          className={` capitalize text-white-2 truncated-text ${
            isSubMenu ? "font-17-23-400" : "font-18-600-24"
          }`}
        >
          {title}
        </span>
      </Link>
    </div>
  );
};

export default MenuItem;
