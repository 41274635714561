import { useState } from "react";
import { toast } from "react-toastify";

import ApiService from "../../helpers/Services.js";
import ModalFormSkeleton from "../skeleton/ModalFormSkeleton.js";
import Modal from "../modal/Modal.js";

const AssignRole = ({ title, onClose, fetchUsers, ...props }) => {
  const { email, title: selectedTitle, isAssignRole, roleName } = props.userRef;
  console.log({ roleName });
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [checkedRoles, setCheckedRoles] = useState({
    Admin: roleName === "Admin" || false,
    Frontline: roleName === "Frontlinw" || false,
    Supervisor: roleName === "Supervisor" || false,
  });

  const handleCheckboxChange = (id, isChecked) => {
    setCheckedRoles((prevRoles) => ({
      ...prevRoles,
      [id]: isChecked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const rolesToAssign = Object.entries(checkedRoles)
      .filter(([_, isChecked]) => isChecked)
      .map(([id]) => id);

    if (!rolesToAssign.length) {
      alert("Please select a role before submitting.");
      return;
    }

    try {
      await ApiService.post("user-management", {
        action: "updateUserRoles",
        payload: {
          vendor: "MT",
          email: email,
          roles: rolesToAssign,
        },
      });

      toast.success(`${title} Successfully!`, {
        position: "top-right",
      });
      fetchUsers();
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }

    onClose(false);
  };

  const fetchRoles = async () => {
    try {
      setIsLoading(true);
      const data = await ApiService.post("user-management", {
        action: "listRoles",
        payload: { vendor: "MT" },
      });

      setRoles(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useState(() => {
    fetchRoles();
  }, []);

  return (
    <Modal title={title} onClose={onClose}>
      <div className="">
        <div className="modal-user">
          {isLoading ? (
            <ModalFormSkeleton />
          ) : (
            <form onSubmit={handleSubmit} className="px-4 my-2 py-2">
              <h2 className="text-black font-23-34-400 py-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. 
              </h2>
              <div className="flex flex-col my-4 ">
                <label className="mb-2 font-20-30-400" id="email">
                  Email
                </label>
                <input
                  type="email"
                  className="border-input font-16-21-400 font-normal  w-3/4 px-3 h-46  br-6"
                  disabled
                  value={email}
                />
              </div>

              {/* {!isAssignRole && (
                <div className="role-assign-txt">
                  Please check the roles you want to keep.
                </div>
              )} */}
              <label className="mb-2 font-20-30-400" id="email">
                Role Type
              </label>
              <div className=" flex items-center gap-48 ">
                {roles?.map(({ rolename }) => (
                  <div
                    key={rolename}
                    className="flex items-center justify-center gap-20"
                  >
                    <input
                      type="checkbox"
                      id={rolename}
                      className="m-0"
                      checked={checkedRoles[rolename]}
                      onChange={(e) =>
                        handleCheckboxChange(rolename, e.target.checked)
                      }
                    />
                    <label
                      htmlFor={rolename}
                      className="font-18-400-24 ml-2  m-0"
                    >
                      {rolename}
                    </label>
                  </div>
                ))}
              </div>

              <div className="py-2 w-182 mt-10 mb-4">
                <button className=" border-none w-full h-49 font-18-600-24 capitalize justify-center rounded-md bg-[#3C394D] px-8  text-sm font-semibold text-white shadow-sm  sm:w-auto">
                  Assign Role
                </button>
              </div>
              {/* <button
                className={isAssignRole ? "bg-assign-role" : "remove-role-btn"}
              >
                {isAssignRole ? "Assign Role" : "Remove Role"}
              </button> */}
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AssignRole;
