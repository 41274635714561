import { Outlet } from "react-router-dom";

import Navbar from "../navbar/Navbar.js";
import SideBar from "../sidebar/SideBar.js";

export const AdminLayout = () => {
  return (
    <div className="main">
      <Navbar />
      <div className="flex">
        <div className="w-333 h-screen">
          <SideBar />
        </div>
        <div className="contentContainer  bg-white">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
